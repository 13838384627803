import { type FC } from 'react';
import { Textarea } from 'flowbite-react';
import { type FieldErrors, type UseFormRegisterReturn } from 'react-hook-form';
import FormLabel from './FormLable.tsx';

type FormTextAreaProps = {
  label: string;
  attribute: string;
  register: UseFormRegisterReturn<string>;
  errors: FieldErrors<any>;
  required: boolean;
  rows: number;
};

const FormTextArea: FC<FormTextAreaProps> = (props) => (
  <div className="mt-5 mb-5">
    <FormLabel
      label={props.label}
      attribute={props.attribute}
      required={props.required}
    />
    <Textarea
      id={props.attribute}
      required={props.required}
      rows={props.rows}
      {...props.register}
    />
    {props.errors[props.attribute] && (
      <div className="error_messages">{`${props.label}を入力してください`}</div>
    )}
  </div>
);

export default FormTextArea;
